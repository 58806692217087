<div class='wrapper page container'>
  <div class="row page-title">
    <div class="col-md-12 col-xs-12">
      <h3 class="float-left m-bottom-0">{{'profile.rate_profile' | translate}}</h3>
      <button *ngIf="profile=='SUPERADMIN' || profile=='RESELLER' || (merchantInfo && merchantInfo.state != 'INACTIVE')" type="button" [routerLink]="['./new']"  class="btn blue-bg mg-12 float-right header-button">{{'profile.new_profile' | translate}}</button>
    </div>
  </div>
  <div class="card">
    <div class="w100 app-search">
      <div class="w800">

        <!-- <app-advance-search [page]="profile-rate" [sortOptions]="sortOptions" [sortOptionsPlaceholder]="'profile.sort'"
        [selectOptionsPlaceholder]="'profile.selectUsers'" (deletedChip)="chipDeleted()"
        (searchEvent)="searchEvent($event)" (sortEvent)="sortEvent($event)" [options]="options" (changeAlert)="getAllRates()"></app-advance-search> -->

        <!--<app-filter-search (searchEvent)="searchEvent($event)" [page]="'profile-rate'"
          [sortOptions]="sortOptions" [sortOptionsPlaceholder]="'profile.sort'"
          (sortEvent)="sortEvent($event)">
        </app-filter-search>-->

        <sp-filter-section [showCancelButton]="searchForm.dirty" (searchClicked)="prepareForSearch()" (cancelClicked)="prepareForCancel()">
          <form [formGroup]="searchForm">
            <div class="row">
              <sp-input-text class="col-md-4 col-sm-6 mt-2" [control]="searchForm.controls.name" [label]="'general.name'" [placeholder]="'general.name'"></sp-input-text>
              <sp-input-text class="col-md-4 col-sm-6 mt-2" [control]="searchForm.controls.instalmentNumber" [type]="'number'" [label]="'advance-search.instalment_number'" [placeholder]="'advance-search.instalment_number'"></sp-input-text>
              <sp-input-instalment-frequency-select class="col-md-4 col-sm-6 mt-2" [control]="searchForm.controls.instalmentFrequency" [multiple]="true" [label]="'general.instalment_frequency'"
                [placeholder]="'advance-search.select_instalment_frequency'"></sp-input-instalment-frequency-select>
              <sp-input-first-instalment-type-select class="col-md-4 col-sm-6 mt-2" [control]="searchForm.controls.firstInstalmentType" [multiple]="true" [label]="'general.first_instalment_type'"
                [placeholder]="'advance-search.select_first_instalment_type'"></sp-input-first-instalment-type-select>
              <sp-input-status-select class="col-md-4 col-sm-6 mt-2" [control]="searchForm.controls.enabled" [label]="'general.state'" [placeholder]="'advance-search.select_state'"></sp-input-status-select>
              <sp-input-merchant-select class="col-md-4 col-sm-6 mt-2" [control]="searchForm.controls.idMerchant" [label]="'general.merchant'" [placeholder]="'advance-search.select_merchant'"
                *ngIf="(profile=='SUPERADMIN' || profile=='RESELLER') && !userSelected"></sp-input-merchant-select>
            </div>
          </form>
        </sp-filter-section>

      </div>
    </div>
    <div class="table-margin">
      <ngx-datatable #dataTable class='material outer-table expandable data-table' [columnMode]="'force'" [headerHeight]="0"
        [footerHeight]="70" [rowHeight]="100" [scrollbarH]="true" [scrollbarV]="false" [rows]='rows' *ngIf="rows.length > 0"
        [limit]="tableRecordNumbers" (page)="onPage($event)" (select)='onSelect($event)' [sorts]="[{prop: sortProp, dir: 'asc'}]" [messages]="my_messages">        
        <ngx-datatable-column name="Name" prop="description" [width]="250">
          <ng-template let-rowIndex="rowIndex" let-row="row" ngx-datatable-cell-template>
            <div>
              <h4 class="text-clamp" title="{{row.description}}">{{row.description}}</h4>
                <!-- <div>{{row.instalmentNumber}} {{'general.rate' | translate}} {{ row.instalmentFrequency }} </div> -->
                <ng-template [ngIf]="row.instalmentFrequency == 'MENSILE'">
                  <div>{{row.instalmentNumber}} {{'general.rate' | translate}} {{'rata.frequenza_mensile' | translate}}</div>
                </ng-template>
                <ng-template [ngIf]="row.instalmentFrequency == 'BIMESTRALE'">
                  <div>{{row.instalmentNumber}} {{'general.rate' | translate}} {{'rata.frequenza_bimestrale' | translate}}</div>
                </ng-template>
                <ng-template [ngIf]="row.instalmentFrequency == 'TRIMESTRALE'">
                  <div>{{row.instalmentNumber}} {{'general.rate' | translate}} {{'rata.frequenza_trimestrale' | translate}}</div>
                </ng-template>
                <ng-template [ngIf]="row.instalmentFrequency == 'SEMESTRALE'">
                  <div>{{row.instalmentNumber}} {{'general.rate' | translate}} {{'rata.frequenza_semestrale' | translate}}</div>
                </ng-template>
                <ng-template [ngIf]="row.instalmentFrequency == 'ANNUALE'">
                  <div>{{row.instalmentNumber}} {{'general.rate' | translate}} {{'rata.frequenza_annuale' | translate}}</div>
                </ng-template>
            </div>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [width]="150">
          <ng-template let-rowIndex="rowIndex" let-row="row" ngx-datatable-cell-template>
          <div class="a-center">
            <h4>{{row.firstInstalmentValue || '-'}}</h4>
            <div class="sec-text-color">{{'rata.valore-prima-rata' | translate}}</div>
          </div>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [width]="200">
          <ng-template let-rowIndex="rowIndex" let-row="row" ngx-datatable-cell-template>
            <div class="a-center">
              <h4>{{row.startDelay}}</h4>
              <div class="sec-text-color">{{'rata.rata-dopo-giorni' | translate}}</div>
            </div>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Status" [width]="200">
          <ng-template let-rowIndex="rowIndex" let-row="row" ngx-datatable-cell-template>
            <div class="row center-row">
              <div>
                <div *ngIf="row.enabled"  class="status-div status-div-table  attivo">{{'general.enabled' | translate}}</div>
                <div *ngIf="!row.enabled"  class="status-div  status-div-table  bloccato">{{'general.disabled' | translate}}</div>
              </div>
              <!--<div class="status-pencil">
                <app-status-select [allStatus]="allStatus" (hideSelectEvent)="hideSelectEvent(row)" (statusSelectedEvent)="statusSelectedEvent($event,row)"></app-status-select>
              </div>-->
            </div>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [width]="200" [resizeable]="false" [sortable]="false" [draggable]="false" [canAutoResize]="false">
          <ng-template let-row="row" let-expanded="expanded" ngx-datatable-cell-template>
            <div class="f-right">
              
              <button [disabled]="!canEdit(row)" class="blue-bg details-button" 
                title="Expand/Collapse Row"
                [routerLink]="['./edit/'+ row.id]" >
                <span>{{'sale.modificate' | translate}}</span>
              </button>
              <a href="#" class="moredd dropdown-toggle nav-link blue-bg sec-butt" data-bs-toggle="dropdown" role="button" aria-expanded="false" style=" text-decoration: none;">
                <span class="caret"></span>
              </a>
              <ul class="dropdown-menu detail-dropdrown" role="menu">
                <li class="nav-item">
                  <a class="nav-link edit action_enabled" *ngIf="canEdit(row)" (click)="toggleActivation(row)">
                    <ng-container *ngIf="!row.enabled">{{'general.activate' | translate}}</ng-container>
                    <ng-container *ngIf="row.enabled">{{'general.deactivate' | translate}}</ng-container>
                  </a>
                  <a class="nav-link edit action_disabled" *ngIf="!hasActionToShow(row)">{{'general.label_empty_options' | translate}}</a>
                </li>
              </ul>
            </div>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-footer *ngIf="true">
          <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize" let-selectedCount="selectedCount" let-curPage="curPage" let-offset="offset" >
            
            <div *ngIf="rowCount > 0" style="padding-left: 20px;">
              {{ rowCount }} {{ 'datatable_message.lista_totali_righe' | translate }}
            </div>
            <div *ngIf="rowCount == 0" style="padding-left: 20px;">
              {{ 'datatable_message.lista_vuota_righe' | translate }}
            </div>
            <datatable-pager
              [pagerLeftArrowIcon]="'datatable-icon-left'"
              [pagerRightArrowIcon]="'datatable-icon-right'"
              [pagerPreviousIcon]="'datatable-icon-prev'"
              [pagerNextIcon]="'datatable-icon-skip'"
              [page]="curPage"
              [size]="pageSize"
              [count]="rowCount"
              [hidden]="!((rowCount / pageSize) > 1)"
              (change)="table.onFooterPage($event)">
            </datatable-pager>
          </ng-template>
        </ngx-datatable-footer>

      </ngx-datatable>
      <div class="" *ngIf="rows.length == 0">
        <h4 class="a-center">{{ 'statistics.empty_table' | translate }}</h4>
      </div>
    </div>
  </div>

  <div id="sidebar-wrapper" *ngIf="profile=='SUPERADMIN' || profile=='RESELLER'">
    <app-sidebar-users [users]="users" (selectedUser)="selectedUser($event)"></app-sidebar-users>
  </div>

</div>

