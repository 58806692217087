import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { Routes, RouterModule } from '@angular/router';
import { SharedModule } from '../../shared/shared.module';
import { ProfileRateComponent } from './profile-rate/profile-rate.component';
import { ProfileRateInsertComponent } from './profile-rate-insert/profile-rate-insert.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { BsDropdownDirective } from 'ngx-bootstrap/dropdown';

const routes: Routes = [
  { path: '', component: ProfileRateComponent },
  { path: 'new', component: ProfileRateInsertComponent },
  { path: 'edit/:id', component: ProfileRateInsertComponent }
];

@NgModule({
  imports: [
    SharedModule,
    CommonModule,
    RouterModule.forChild(routes),
    NgxDatatableModule,
    NgSelectModule
  ],
  providers: [
    BsDropdownDirective
  ],
  declarations: [
    ProfileRateComponent,
    ProfileRateInsertComponent
  ]
})
export class ProfileRateModule { }
