<div class="container o-x">
  <div class="row page-title">
    <div class="col-md-12 col-xs-12">
      <h3 class="float-left m-bottom-0" *ngIf="!idProfileRate">{{'profile.new_profile' | translate}}</h3>
      <h3 class="float-left m-bottom-0" *ngIf="idProfileRate">{{'profile.edit_profile' | translate}}</h3>
      <button type="button" class="btn btn-danger float-right button-style" (click)="navigationBack()">{{'insert.exit' |
        translate}}</button>
    </div>
  </div>
  <div class="row card">
    <div class="col-md-12 col-xs-12 white-row">
      <form [formGroup]="form">
        <div class="row">
          <div class="col-md-6">
            <div class="has-float-label">
              <input class="custom-float-label-input" formControlName="description" type="text" placeholder=" " id="description"
                required>
              <label class="label custom-label" for="description"> {{'profile.profile_name' | translate}} </label>
            </div>
          </div>
          <div class="col-md-6">

          </div>
          <div class="col-md-6">
              <div class="has-float-label">
              <ng-select class="custom" placeholder="{{ 'profile.n_installment' | translate }} " formControlName="instalmentNumber">
                <ng-option *ngFor="let day of days" [value]="day.number">{{day.number}}</ng-option>
              </ng-select>
              </div>
          </div>
          <div class="col-md-6">
            <div class="has-float-label">
              <!-- <ng-select class="custom" placeholder="{{ 'profile.frequency_rate' | translate }}" formControlName="instalmentFrequency"
                (change)="onChangeFrequency($event)" (open)="setOptionText($event)"> -->
                <!-- <ng-option *ngFor="let frequency of frequencies" [value]="frequency">{{frequency.name}}</ng-option> -->
                <!-- <ng-option *ngFor="let frequency of frequencies" [value]="frequency">{{frequency.output}}</ng-option> -->
                <!-- <ng-option *ngFor="let frequency of frequencies" [value]="frequency">{{ 'rata.'+frequency.output | translate }}</ng-option> -->
                <!-- <ng-option *ngFor="let frequency of frequencies" [value]="frequency">{{translate.instant('rata.frequenza_1')}}</ng-option> -->
                <!-- <ng-option *ngFor="let frequency of frequencies" [value]="frequency">{{ 'profile.frequency_rate' | translate }}</ng-option> -->
              <!-- </ng-select> -->

              <ng-select class="custom" placeholder="{{ 'profile.frequency_rate' | translate }}" formControlName="instalmentFrequency" (change)="onChangeFrequency($event)"> <!-- [(ngModel)]="selectedCarId" // [items]="frequencies" bindLabel="name" bindValue="nested.countryId" -->
                <!-- <ng-option *ngFor="let frequency of frequencies" [value]="frequency.id" >{{ frequency.output }}</ng-option> -->
                <!-- <ng-option [value]="'custom'">Custom</ng-option> -->
                <ng-option *ngFor="let frequency of frequencies" [value]="frequency" >{{frequency.name}}</ng-option>
                <ng-template ng-option-tmp let-item="item" >
                    {{ 'rata.'+item.output | translate }}
                </ng-template>
                <ng-template ng-label-tmp let-item="item">
                  {{ 'rata.'+item.output | translate }}
                </ng-template>
              </ng-select>
              
            </div>
          </div>

          <div class="col-md-6">
            <div class="has-float-label">
              <input class="custom-float-label-input" formControlName="startDelay" type="number" placeholder=" "
                id="startDelay" required>
              <label class="label custom-label" for="startDelay"> {{'profile.installment_begin' | translate}}
              </label>
            </div>
          </div>
          <div class="col-md-6">
            <div class="has-float-label">
            <!-- <ng-select class="custom" placeholder="{{ 'profile.first_installment' | translate }}" formControlName="firstInstalmentType"
              (change)="onChangeFirstInstalmentType($event)" (open)="setOptionText($event)">
              <ng-option *ngFor="let type of firstInstalmentTypes" [value]="type">{{'rata.'+type.output | translate }}</ng-option>
            </ng-select> -->
            <ng-select class="custom" placeholder="{{ 'profile.first_installment' | translate }}" formControlName="firstInstalmentType" 
              (change)="onChangeFirstInstalmentType($event)">
              <ng-option *ngFor="let type of firstInstalmentTypes" [value]="type">{{ type.name }}</ng-option>
              <ng-template ng-option-tmp let-item="item" >
                  {{ 'rata.'+item.output | translate }}
              </ng-template>
              <ng-template ng-label-tmp let-item="item">
                {{ 'rata.'+item.output | translate }}
              </ng-template>
            </ng-select>

            </div>
          </div>
          <div class="col-md-6 h80">
            <div *ngIf="firstInstalmentType">
              <div class=" has-float-label" *ngIf="firstInstalmentType.id == 1">
                <input pattern="(^100(\.0{1,2})?$)|(^([1-9]([0-9])?|0)(\.[0-9]{1,2})?$)" class="custom-float-label-input"
                  formControlName="firstInstalmentValue" type="number" placeholder=" " id="start_installment" required>
                <label class="label custom-label in-s" for="start_installment"> {{'rata.prima-rata' | translate}} </label>
              </div>
              <div class=" has-float-label" *ngIf="firstInstalmentType.id == 2">
                <input [disabled]="firstInstalmentType.id ==3" class="custom-float-label-input" formControlName="firstInstalmentValue"
                  type="number" placeholder=" " id="start_installment" required>
                <label class="label custom-label" for="start_installment"> {{'rata.valore-prima-rata' | translate}}
                </label>
              </div>
            </div>
          </div>
        </div>
      </form>
      <div class="g-button-position">
        <button class="btn btn-success save60" type="button" (click)="onSubmit()">{{submitButtonText}}</button>
      </div>
    </div>
  </div>
</div>
