import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import { AppService } from '../../../core/services/base.service';
import swal from 'sweetalert2';
import { SettingsService } from '../../../core/settings/settings.service';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SharedService } from '../../../shared/shared.service';
import { RouterExtService } from '../../../core/services/router.ext.service';

@Component({
  selector: 'app-profile-rate-insert',
  templateUrl: './profile-rate-insert.component.html',
  styleUrls: ['./profile-rate-insert.component.scss']
})
export class ProfileRateInsertComponent implements OnInit {
  submitButtonText:String;

  firstInstalmentType: any = {}
  selectedFrequence: any;
  idProfileRate: any;
  form = this.fb.group({
    "description": ['',Validators.required],
    "startDelay": ['',Validators.required],
    "instalmentNumber": [null,Validators.required],
    "instalmentFrequency": [null,Validators.required],
    "firstInstalmentType": [null,Validators.required],
    "firstInstalmentValue": [null,Validators.required]
  })

  days = [
    { number: 1 },
    { number: 2 },
    { number: 3 },
    { number: 4 },
    { number: 5 },
    { number: 6 },
    { number: 7 },
    { number: 8 },
    { number: 9 },
    { number: 10 },
    { number: 11 },
    { number: 12 },
  ]

  frequencies = [{
    id: 1, name: "MENSILE", output : "frequenza_1"
  }, {
    id: 2, name: "BIMESTRALE", output : "frequenza_2"
  }, {
    id: 3, name: "TRIMESTRALE", output : "frequenza_3"
  }, {
    id: 4, name: "SEMESTRALE", output : "frequenza_6"
  }, {
    id: 5, name: "ANNUALE", output : "frequenza_12"
  }]

  firstInstalmentTypes = [{
    "id": 1, "name": "PERCENTUALE", output : "tipo_rata_1"
  }, {
    "id": 2, "name": "VALORE_FISSO", output : "tipo_rata_2"
  }, {
    "id": 3, "name": "UNIFORME", output : "tipo_rata_3"
  }]

  constructor(private route: ActivatedRoute,
    private translate: TranslateService,
    private fb: UntypedFormBuilder,
    private location: Location,
    private appService: AppService,
    private settings: SettingsService,
    private router: Router,
    private sharedService: SharedService,
    private routerExtService: RouterExtService) { }

  ngOnInit() { 

    this.route.params.subscribe(params => {
      this.idProfileRate = params['id'];
      if (this.idProfileRate != null) {
        this.getProfileRateDetails(this.idProfileRate);
        this.translate.get('profile.update').subscribe((text:string) => this.submitButtonText = text);
      }
      else {
        this.translate.get('profile.create').subscribe((text:string) => this.submitButtonText = text);
      }

    })

  }

  ngDoCheck() {
  }

  ngAfterContentInit() {
  }

  ngAfterContentChecked() {    
  }
  
  onChangeFirstInstalmentType(event) {
    if (event) {
      this.firstInstalmentType = event;
      this.form.controls['firstInstalmentValue'].setValidators([Validators.required])
      this.form.controls['firstInstalmentValue'].updateValueAndValidity()
      this.form.get('firstInstalmentValue').reset()
      if (event.id == 3) {
        this.form.get('firstInstalmentValue').disable()
      }
      else {
        this.form.get('firstInstalmentValue').enable()
      }
    } else {
      this.firstInstalmentType = null
    }
  }

  onChangeFrequency(event) {
    this.selectedFrequence = event;
  }

  getProfileRateDetails(idProfileRate) {
    this.appService.getElement('/instalment-plan/get/' + idProfileRate).subscribe((res: any) => {
      this.form.setValue({
        "description": res.description,
        "startDelay": res.startDelay,
        "instalmentNumber": res.instalmentNumber,
        "instalmentFrequency": res.instalmentFrequency,
        "firstInstalmentType": res.firstInstalmentType,
        "firstInstalmentValue": res.firstInstalmentValue
      })
      if (res.firstInstalmentType == 'UNIFORME') {
        this.form.get('firstInstalmentValue').reset()
        this.form.get('firstInstalmentValue').disable()
      }
      this.selectedFrequence = this.findItemOnArray(res.instalmentFrequency, this.frequencies)
      this.firstInstalmentType = this.findItemOnArray(res.firstInstalmentType, this.firstInstalmentTypes)
    })
  }
  findItemOnArray(item, array) {
    for (let i = 0; i < array.length; i++) {
      if (item == array[i].name) {
        return array[i]
      }
    }
    return
  }
  onSubmit() {
    for (var i in this.form.controls) {
      this.form.controls[i].markAsTouched();
    }
    if (this.form.status == 'VALID') {
      this.form.value.firstInstalmentType = this.firstInstalmentType.name;
      this.form.value.instalmentFrequency = this.selectedFrequence ? this.selectedFrequence.name:"";
      this.insertProfile(this.form.value)
    }
  }

  insertProfile(data) {
    if (this.idProfileRate) {
      data['id'] = this.idProfileRate;
      this.appService.editElement('/instalment-plan', data).subscribe((res: any) => {
        if (res.outcome && res.outcome.success === true) {
          swal.fire("Il profilo è stato modificato", "", "success").then((result) => {
            this.location.back();
          });
        } else {
          swal.fire("Error", this.settings.manageErrorMsg(res.outcome), "error");
        }
      }, error => {
        swal.fire("Error", error, "error");
      })
    } else {
      this.appService.newElement('/instalment-plan', data).subscribe((res: any) => {
        if (res.outcome.success === true) {
          swal.fire("Il nuovo profilo di rate è stato creato", "", "success");
          this.router.navigate(['/profile-rate']);
          // this.location.back();
        } else {
          swal.fire("Error", this.settings.manageErrorMsg(res.outcome), "error");
          this.router.navigate(['/profile-rate']);
        }
      }, error => {
        swal.fire("Error", error, "error");
      })
    }
  }

  navigationBack() {
    if (!this.routerExtService.getPreviousUrl() ||
      this.routerExtService.getPreviousUrl() == this.router.url ||
      this.routerExtService.getPreviousUrl().startsWith('/login')) {
      this.router.navigateByUrl('/profile-rate');
    } else {
      this.location.back();
    }
  }

}
